<template>
    <div class="pt-1 px-3">
        <div class="h-100 flex-fill d-flex flex-column">
            <div class="">
                <router-link to="/bussiness" class="h4 mb-0 text-white d-flex align-items-center" style="">
                    <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                    Back
                </router-link>
            </div>

            <h3 class="text-center font-weight-bolder text-white mt-2">Became A Trainer</h3> 

            <div class="mt-2">
                <label class="h3 text-white">Trainer Speciality</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-user"></i></span>
                    <input class="form-control" placeholder="Trainer Speciality" v-model="title">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.speciality && isSubmit" style="margin-top: .5em;">
                    Speciality not valid</div>

            </div>

            <div class="mt-2">
                <label class="h3 text-white">Description</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-comment"></i></span>
                    <textarea class="form-control" placeholder="Description" v-model="description"></textarea>
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.description && isSubmit" style="margin-top: .5em;">
                    Description not valid</div>
            </div>

            <form id="frm">
                <div class="mt-2">
                    <label class="h3 text-white">Prove of Identity</label>
                    <div class="input-group">
                        <span class="input-group-text text-white"><i class="bx bx-image"></i></span>
                        <input class="form-control" type="file" name="trainer_docs" placeholder="Phone">
                    </div>
                    <div class="text-white" style="margin-top: .5em;">*This will be the document to verify your service
                    </div>
                </div>
            </form>

            <div class="accordion mt-2" role="tablist">
                <b-card no-body class="mb-0">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block v-b-toggle.accordion-1 variant="primary">Terms & Condition</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body style="max-height: 200px;overflow-y: scroll;">
                            <p class="">
                                Thank you for expressing your interest in becoming a registered pet trainer with our organization. Your
                                commitment to enhancing the lives of pets through training is highly appreciated. Before we embark on this
                                journey together, please review the following terms and conditions. <br /> <br />
                                To complete your registration, we ask for accurate details regarding your experience, qualifications, and
                                training methodologies. We value trainers who prioritize positive reinforcement and maintain ethical
                                standards in their work. As part of our commitment to excellence, your registration may undergo an
                                evaluation process to ensure alignment with our organization's values. Upon approval, you will be expected
                                to adhere to the agreed-upon schedule and uphold a professional, compassionate demeanor during all training
                                sessions. <br /> <br />
                                Compensation details and payment terms will be discussed in detail upon the approval of your registration.
                                We encourage open communication with clients, emphasizing the importance of addressing any concerns promptly
                                and professionally. By registering as a pet trainer with our organization, you not only gain a platform for
                                your expertise but also commit to providing quality training services that prioritize the well-being of our
                                clients' pets.
                            </p>
                        </b-card-body>
                    </b-collapse>
                </b-card> 
            </div>

            <div class="mt-2 w-100 d-flex flex-column justify-content-center" style="gap:10px">
                <button class="btn text-white flex-fill" style="background-color: #187294;height:50px;font-size: 18px;"
                    @click="onSubmit()">
                    Im Ready!
                </button>
            </div>

        </div>
    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie';
let cookies = new Cookies()

import {BCardHeader, BCard, BCollapse, BCardBody, BButton} from 'bootstrap-vue'

export default {
    components: {
        BCardHeader, BCard, BCollapse, BCardBody, BButton
    },
    computed: {
        valid() {
            return {
                speciality: this.title?.length > 0,
                description: this.description?.length > 0,
            }
        },
        allValid() {
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        },
        user(){
            return store.state.auth.USER
        },
        trainer(){
            return this.user?.trainer
        },
    },
    watch: {
        trainer: {
            handler: function(val){
                if(val){
                    this.title = val.trainer_title
                    this.description = val.trainer_description 
                }
            }, immediate: true, deep: true
        }
    },
    methods: {
        onSubmit() {
            this.isSubmit = true
            if (this.allValid) {
                let formData = new FormData($('#frm')[0])
                formData.append('id', cookies.get('token'))
                formData.append('title', this.title)
                formData.append('description', this.description)

                store.dispatch('trainer/BecameATrainer', formData).then(() => {
                    store.dispatch('auth/GetUserData')
                })
            }
        }
    },
    data() {
        return {
            title: '',
            description: '',
            isSubmit: false
        }
    }
}

</script>

<style>
.auth-page .btn {
    border-radius: 20px;
}

input:focus,
textarea:focus {
    color: white !important;
}

.input-group span {
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    font-size: 24px;
    color: #1F9BCD;
    background-color: transparent;
}

.input-group input,
.input-group textarea {
    margin-left: 0;
    padding-left: 0;
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    height: 40px;
    color: white;
    background-color: transparent !important;
}

select.form-control,
select.form-control:active {
    color: white !important
}

select.form-control option,
select.form-control:active option {
    color: white !important;
    background-color: #1B628C !important;
}</style>